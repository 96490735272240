import { useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { AccordionItemConfigComponent } from '@/components/common/Accordion/AccordionItemConfig';
import { DefaultAccordionItem } from '@/components/common/Accordion/DefaultAccordionItem';

import type { DefaultAccordionItemProps } from '@/components/common/Accordion/DefaultAccordionItem';
import type { AccordionHeadingPaddingOptions } from '@/components/common/Accordion/utils';
import type { IconSizesSet } from '@/components/common/Icon';
import type { BackgroundColourTokenType } from '@/types/colours';
import type { BlockWithAudience } from '@/types/shared';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

// Interface for accordionItems to follow.
export interface GeneralAccordionItemProps {
  // heading?: heading appears at top of the accordion item
  heading: string;

  // tabTitle?: title appears left to the heading
  tabTitle?: string;

  _key?: string;

  // component?: component to render for the accordion item. Defaults to DefaultAccordionItem
  component?: ReactNode;

  // isExpandable?: false makes accordionItem non-expandable
  isExpandable?: boolean;
}

export interface AccordionProps extends BlockWithAudience {
  className?: string;
  backgroundColour?: BackgroundColourTokenType;

  accordionItems: Array<DefaultAccordionItemProps | GeneralAccordionItemProps>;

  // accordionBehaviour?: 'singleItem' to allow only 1 item to be opened at the same time.
  // Defaults to 'singleItem'
  accordionBehaviour?: 'singleItem' | 'allItems';

  // firstItemInAllItemsOpened?: allows first item to be opened by default when using 'allItems' config
  // Default: false
  firstItemInAllItemsOpened?: boolean;

  // openItemsState?: state to control which items are opened for single item behaviour
  openItemsState?: [number[], Dispatch<SetStateAction<number[]>>];

  // timer?: amount of seconds to run timer animation on each accordion item
  timer?: number;

  // isInView?: true if accordion is in the view for timer animation
  isInView?: boolean;

  // isOldStyleChevron?: true to use FAQ-style (--icon-action) styling on chevron
  isOldStyleChevron?: boolean;

  // chevronSize?: specify chevron size
  chevronSize?: string | IconSizesSet;

  // isTransparentChevron?: chevron has no background colour
  isTransparentChevron?: boolean;

  /* 
  startingIndex?: starting index of an accordion item to not be opened when clicked on items
   in co-existing accordions.

  - should be equal to length of previous accordion(-s)

  Example: 
    Accordion 1 : {AccordionItemConfig index 0, AccordionItemConfig index 1}

    startingIndex = 2;
    
    Accordion 2 : {AccordionItemConfig index 0 + startingIndex, AccordionItemConfig index 1 + startingIndex}

   */
  startingIndex?: number;

  // headingPadding?: padding applied on the heading-buttons in open and closed states
  headingPadding?: {
    openState?: AccordionHeadingPaddingOptions;
    closedState?: AccordionHeadingPaddingOptions;
  };
}

export const Accordion = ({
  className,
  backgroundColour,
  pageAudience,
  isAudienceSwitcherEnabled,
  accordionItems,
  accordionBehaviour = 'singleItem',
  firstItemInAllItemsOpened = false,
  openItemsState,
  timer,
  isInView,
  chevronSize = '16px',
  isOldStyleChevron = false,
  isTransparentChevron = false,
  startingIndex = 0,
  headingPadding,
}: AccordionProps) => {
  const [stopAnimation, setStopAnimation] = useState(false);
  const [pauseAnimation, setPauseAnimation] = useState(false);

  return (
    <StyledAccordionList
      onClick={() => setStopAnimation(true)}
      onMouseEnter={() => setPauseAnimation(true)}
      onMouseLeave={() => setPauseAnimation(false)}
      className={clsx(className, 'accordion')}
      backgroundColour={backgroundColour}
    >
      {accordionItems?.map((item, idx) => {
        return (
          <AccordionItemConfigComponent
            accordionBehaviour={accordionBehaviour}
            key={item._key}
            singleAccordionItem={accordionItems?.length === 1}
            timerAnimationConfig={{
              itemOrder: idx,
              itemsAmount: accordionItems.length,
              timer: !stopAnimation && timer,
              pauseAnimation,
              isInView,
            }}
            isOpenedByDefault={
              firstItemInAllItemsOpened && idx + startingIndex === 0
            }
            singleItemBehaviourConfig={{
              idx: idx + startingIndex,
              openItemsState,
            }}
            pageAudience={pageAudience}
            isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
            isExpandable={item.isExpandable}
            chevronConfig={{
              chevronSize,
              isOldStyleChevron,
              isTransparentChevron,
            }}
            headingPadding={headingPadding}
            {...item}
          >
            {item.component ?? (
              <DefaultAccordionItem
                {...item}
                pageAudience={pageAudience}
                isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
              />
            )}
          </AccordionItemConfigComponent>
        );
      })}
    </StyledAccordionList>
  );
};

const StyledAccordionList = styled.ul<{ backgroundColour }>`
  background: ${({ backgroundColour }) => `var(${backgroundColour})`};

  li {
    color: var(--text-strong);
  }
`;
