import { css } from '@emotion/react';

import { colourTokens } from '@/styles/colours';

import type { AccordionItemConfigProps } from '@/components/common/Accordion/AccordionItemConfig';
import type { Dispatch, SetStateAction } from 'react';

// PaddingBottom should be applied to the OuterWrapper to align with the animation
export type AccordionHeadingPaddingOptions = {
  paddingTop?: string;
  paddingRight?: string;
  paddingLeft?: string;
};

export const singleItemBehaviour = (
  singleItemBehaviourConfig: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  },
  memo: boolean,
) => {
  const idx = singleItemBehaviourConfig.idx;
  const [openItems, setOpenItems] = singleItemBehaviourConfig.openItemsState;

  return {
    ariaExpanded: openItems.includes(idx),
    isOpen: memo,
    onClick: (e) => {
      e.stopPropagation();
      setOpenItems([idx]);
    },
  };
};

export const allItemsBehaviour = (
  allItemsState: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const [isOpen, setIsOpen] = allItemsState;
  return {
    ariaExpanded: isOpen,
    onClick: (e) => {
      e.stopPropagation();
      setIsOpen((prev) => !prev);
    },
    isOpen: isOpen,
  };
};

export const getChevronColour = (
  isTransparentChevron: boolean,
  isOpen: boolean,
  isOldStyleChevron: boolean,
) => {
  if (isTransparentChevron) {
    return colourTokens.icon.strong.token;
  }
  if (isOpen) {
    if (isOldStyleChevron) {
      return colourTokens.icon.action.token; // OLD style chevron in OPEN state
    } else {
      return colourTokens.icon.inverseStrong.token; // NEW style chevron in OPEN state
    }
  } else if (isOldStyleChevron) {
    return colourTokens.icon.inverseStrong.token; // OLD style chevron in CLOSED state
  } else {
    return colourTokens.icon.warmBase.token; // NEW style chevron in CLOSED state
  }
};

const oldChevronStyle = (isOpen: boolean) => {
  return `
    border: 1.5px solid var(--icon-action);
    padding: var(--spacing-150); 
    background-color: ${isOpen ? 'transparent' : 'var(--icon-action)'};
  `;
};

const newChevronStyle = (isOpen: boolean) => {
  return `
    padding: 6px;
    border: 0px solid var(--icon-action); 
    background-color: ${
      isOpen ? 'var(--surface-inverse-base)' : 'var(--surface-warm-strong)'
    };
  `;
};

export const chevronStyle = (
  isOpen: boolean,
  isOldStyleChevron: boolean,
  isTransparentChevron: boolean,
) => {
  if (isTransparentChevron) {
    return css`
      padding: 6px;
    `;
  } else if (isOldStyleChevron) {
    return oldChevronStyle(isOpen);
  } else {
    return newChevronStyle(isOpen);
  }
};

// Applies title colour, padding and hover states
export const titleStyle = (
  isExpandable: boolean,
  isOpen: boolean,
  headingPadding: AccordionItemConfigProps['headingPadding'],
) => {
  const headingPaddingState = isOpen
    ? headingPadding?.openState
    : headingPadding?.closedState;

  const titleColour = `
    color: ${
      isOpen || !isExpandable ? 'var(--text-strong)' : 'var(--text-warm-base)'
    };
  `;
  let titleStyling: string;

  // If component is expandable (has body and behaves as a proper accordion item)
  // Apply hover states
  if (isExpandable) {
    titleStyling = `
      &:hover {
        color: var(--text-strong);
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--border-strong);
      }

      &:focus {
        text-decoration: none;
        border-radius: var(--radius-component-sm);
        border: 2px solid var(--border-action, #4a5ff7);
        color: var(--text-strong);
      }
    `;
  }
  // When accordion tab is expandable, padding is applied directly on the button to have wider clickable area
  // When tab is not expandable, we need to add padding to the title, as it's not wrapped in a button
  else if (headingPadding) {
    titleStyling = `
      padding-top: ${headingPaddingState.paddingTop};
      padding-right: ${headingPaddingState.paddingRight};
      padding-left: ${headingPaddingState.paddingLeft};
      padding-bottom: 0;
    `;
  } else {
    titleStyling = `
      padding: 0;
    `;
  }

  return [titleColour, titleStyling].join(' ');
};
